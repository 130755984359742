import Cookies, { type Cookie, type CookieSetOptions } from 'universal-cookie'

export class cookies {
  static get<R = any>(name: string, toJson?: boolean): R | void {
    const results = new Cookies().get(name)

    if (results && toJson) {
      return JSON.parse(results)
    }

    return results
  }

  static set(name: string, value: Cookie, options?: CookieSetOptions) {
    if (typeof value !== 'string') {
      value = JSON.stringify(value)
    }

    return new Cookies().set(name, value, this.options(options))
  }

  static remove(name: string, options?: CookieSetOptions) {
    return new Cookies().remove(name, this.options(options))
  }

  static options(options?: CookieSetOptions): CookieSetOptions {
    return {
      domain: location.hostname,
      sameSite: 'strict',
      secure: true,
      ...options
    }
  }
}
